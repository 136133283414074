import React from 'react'
import { Grid, Image } from 'semantic-ui-react'

export default () => (
  <div style={{maxWidth: '1000px'}}>
    <h2 className="content-title">About</h2>
    <p>Clark Trees was started by Daniel Clark in 2008.</p>
    <Grid stackable columns={2}>
      <Grid.Column>
        <Image src='/images/about-darnestown.jpg' centered />
      </Grid.Column>
      <Grid.Column>
        <p>Daniel Clark (Tree Expert) was raised in Darnestown, MD.  
        When to Darnestown Elementary school K-6th grade and now supports the school in fund raisers and events.  
        Daniel has 2 children currently attending Darnestown Elementary school.  
        Daniel decided to create his business in Darnestown MD with the idea to be a key tree business in Darnestown and surrounding areas.</p>
        <p>Tree Experience: In high school Daniel Clark (Tree Expert) split firewood for several years, in Montgomery County.</p>
      </Grid.Column>
    </Grid>
    <Grid stackable reversed="tablet mobile" columns={2}>
      
      <Grid.Column>
        <p>Veteran Owned Business:  Owner SSG Daniel Clark (Tree expert) joined the Army in 2001 and was honorably discharged in 2011.  
        While serving SSG was certified in climbing antenna towers, building towers and removing towers.  
        SSG Clark was trained in climbing telephone poles, installing telephone poles and removing telephone poles.  
        He used a crane bucket truck in the military work.  SSG Clark served two tours overseas, totaling over 28 months of mobilized active duty status.</p>
        <p>SSG Clark took the skills he learned in the military and applied it to creating his own tree service business.</p>
      </Grid.Column>
      <Grid.Column>
        <Image src='/images/about-ssg.jpg' centered />
      </Grid.Column>
    </Grid>
    <Grid stackable columns={2}>
      <Grid.Column>
        <Image src='/images/about-ballet.jpg' centered />
      </Grid.Column>
      <Grid.Column>
        <Image src='/images/about-wootton-football.jpg' centered />
      </Grid.Column>
    </Grid>
    <p className="mt-4">Daniel Clark is also supporting the Rockville Civic Ballet.  
      Daniel started dancing in high school while he was playing football at Wootton.  
      Daniel spends a good amount of time dancing and performing ballet in multiple shows a year. 
      Daniel was on the flyer and danced in “Firebird” and Piano Barre.”  The NutCracker Ballet is the first two weekends in December.
    </p>
    <p>Daniel has 5 kids, 3 girls and 2 boys.</p>
    <Image src='/images/about-family.jpg' />
    <p className="mt-4">Daniel Clark Tree Expert started his business in 2008 and desires to keep customer satisfaction the companies top priority.</p>
    <Grid stackable columns={2}>
      <Grid.Column style={{textAlign: 'center'}}>
        <Image.Group>
          <Image src='/images/about-daniel.jpg' className="ml-4 mr-4" style={{height: '227px'}} />
          <Image src='/images/about-smiley.jpg' className="ml-4 mr-4" />
        </Image.Group>
      </Grid.Column>
      <Grid.Column>
        Daniel Clark is licensed (Tree Expert Lic #2037), and Insured.
      </Grid.Column>
    </Grid>
  </div>
)
