import React from 'react'
import { Image } from 'semantic-ui-react'

export default () => (
  <div className="our-reviews">
    <h2 className="content-title">Reviews</h2>
    <Image.Group  className="mb-5">
      <Image src='/images/review1.png' />
      <Image src='/images/review2.png' />
      <Image src='/images/review3.png' />
    </Image.Group>

    <div className="review-caption mb-5 mt-5">
      <Image src='/images/reviews-overview.jpg' />
      <p>Angie’s List Reviews as of Feb 2018</p>
    </div>

    <table className="a-reviews-table mb-5 mt-5">
      <tbody>
        <tr>
          <td> 
            <span className="a-review-text">"We use Angie's List to assess whether we're doing a good job keeping valued customers like you happy. Please visit AngiesList.com/Review/8663978 in order to grade our quality of work and customer service.</span>
            <Image src='/images/angies-review-us.jpeg' style={{ height:'41px', width: '250px', margin: "0 auto" }} alt="Read Unbiased Consumer Reviews Online at AngiesList.com" />
          </td>
        </tr>
        <tr>
          <td className="a-reviews-link">
            <a href="https://www.angieslist.com/AngiesList/Review/8663978">angieslist.com/review/8663978</a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)
