import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Form, Input, Button, Checkbox, TextArea, Message } from 'semantic-ui-react'
import { post } from '../post';

function Contact() {
  const [state, setState] = useState({ processing: false, completed: false, form_message: '' });
  let refFirstName = useRef();
  let refPhone = useRef();
  let refEmail = useRef();
  let refAddress = useRef();
  let refMessage = useRef();
  let refLastName = useRef();
  let refTree = useRef();
  let refStump = useRef();
  let refTrim = useRef();
  let refOther = useRef();

  const submitForm = async() => {
    if ( state.processing ) return false;
    let message = '';
    setState({ ...state, processing: true, form_message: '' });

    let services = [];
    if ( refTree.current.inputRef.current.checked ) services.push(refTree.current.inputRef.current.value);
    if ( refStump.current.inputRef.current.checked ) services.push(refStump.current.inputRef.current.value);
    if ( refTrim.current.inputRef.current.checked ) services.push(refTrim.current.inputRef.current.value);
    if ( refOther.current.inputRef.current.checked ) services.push(refOther.current.inputRef.current.value);
    let data = { 
      first_name: refFirstName.current.inputRef.current.value,
      last_name: refLastName.current.inputRef.current.value,
      phone: refPhone.current.inputRef.current.value,
      email: refEmail.current.inputRef.current.value,
      address: refAddress.current.inputRef.current.value,
      services,
      message: refMessage.current.ref.current.value,
    };
    // let res = await post('/contact-form/submit', { data: { ...data } });
    let res = await post('/submit-contact/', { data: { ...data } });
    if (res.ok === true) {
      setState({...state, processing: false, completed: true });
    } else {
      if ( res.message ) message = res.message;
      setState({...state, form_message: message, processing: false });
    }
  } // end submitForm

  return (
    <div className="mb-5">
      <h2 className="content-title">Contact</h2>
      <h3>Call us at <a href="tel:240-560-8733">240-560-TREE (8733)</a></h3>
      {state.completed ? <>
        <Message positive><p>Message sent successfully.</p></Message>
      </>
      :
      <Form>
          <Form.Group widths='equal'>
            <Form.Field required><label>First Name</label><Input placeholder='First Name' ref={refFirstName} required fluid autoFocus /></Form.Field>
            <Form.Field required><label>Last Name</label><Input placeholder='Last Name' ref={refLastName} required fluid /></Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field required><label>Phone</label><Input placeholder='Phone' ref={refPhone} required fluid /></Form.Field>
            <Form.Field><label>Email</label><Input placeholder='Email' ref={refEmail} fluid /></Form.Field>
          </Form.Group>
            <Form.Field><label>Address</label><Input placeholder='Address' ref={refAddress} fluid /></Form.Field>
          <Form.Group grouped>
            <label>Services Needed:</label>
            <Form.Field><Checkbox label={'Tree removal'} ref={refTree} value='Tree removal' /></Form.Field>
            <Form.Field><Checkbox label={'Stump grinding'} ref={refStump} value='Stump grinding' /></Form.Field>
            <Form.Field><Checkbox label={'Trimming'} ref={refTrim} value='Trimming' /></Form.Field>
            <Form.Field><Checkbox label={'Other'} ref={refOther} value='Other' /></Form.Field>
          </Form.Group>

          <Form.Field><label>Message</label><TextArea placeholder='How can we help?' ref={refMessage}></TextArea></Form.Field>
          
          <p>By submitting this form, you are agreeing to the <Link to="/privacy-policy" >privacy policy</Link>.</p>

          {state.form_message && <Message negative className="pre-text"><p>{state.form_message}</p></Message>}
          
          <Button onClick={()=>submitForm()} loading={state.processing} primary className="pr-5 pl-5">Submit</Button>
        </Form>
      }
    </div>
  )
} // end Contact

export default Contact