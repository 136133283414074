import React from 'react'
import { Image } from 'semantic-ui-react'

export default () => (
  <div style={{ textAlign: 'center' }}>
    <h1 className="mt-0 pt-0 mb-2">Complete Tree Removal and Trimming Services</h1>
    <h2 className="mt-0 pt-0 mb-2">Residential and Commercial</h2>
    <h3 className="mt-0 pt-0 mb-4">Customer satisfaction is one of our highest priorities.</h3>
    <Image src='/images/clark-trees-smiley.jpg' style={{margin: '0 auto'}} />
  </div>
)
