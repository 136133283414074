import React from 'react'
import { Image } from 'semantic-ui-react'

export default () => (
  <div className="our-services">
    <h2 className="content-title">Our Services</h2>
    <Image.Group style={{textAlign:'center'}}>
      <Image src='/images/our-trimming.jpg' />
      <Image src='/images/our-tree-removal.jpg' />
    </Image.Group>
    <Image.Group style={{textAlign:'center'}}>
      <Image src='/images/our-stump-grinding.jpg' style={{height:'260px'}} />
      <Image src='/images/our-chipping.jpg' />
    </Image.Group>
    <Image.Group style={{textAlign:'center'}}>
      <Image src='/images/our-friendly-service.jpg' />
    </Image.Group>
  </div>
)
