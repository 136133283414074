import React from 'react'

export default () => (
<div>
  <h1>Privacy Policy</h1>

  <h2>How do we protect your information?</h2>

  <p>We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.</p>

  <p>We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. Pages utilizing this technology will have URLs staring with HTTPS instead of HTTP.</p>

  <p>After a transaction, your private information (credit cards) will not be stored on our servers. Clark Tree Services is PCI-DSS compliant.</p>

  <h2>Do we use cookies?</h2>

  <p>Yes (Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers' systems to recognize your browser and capture and remember certain information)</p>

  <p>We use cookies to help us remember and process the items in your shopping cart and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.</p>

  <p>If you prefer, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies via your browser settings. Like most websites, if you turn your cookies off, some of our services may not function properly. However, you can still place orders over the telephone.</p>
  
  <h2>Third Party Advertising</h2>

  <p>Clark Tree Services does not use a third party to deliver ads. Therefore, no information from third party advertising will be collected.</p>
  
  <h2>Do we disclose any information to outside parties?</h2>

  <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
  
  <h2>Childrens Online Privacy Protection Act Compliance</h2>

  <p>We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act of 1998 15 U.S.C. §§ 6501–6506), we do not collect any information from anyone under 13 years of age. In fact, our website, products, and services are all directed to people who are at least 18 years old or older.</p>
  
  <h2>Your Consent</h2>

  <p>By using our site, you consent to our online privacy policy which includes providing permission to Clark Tree Services contact you via phone or email. </p>

  <h2>Changes to our Privacy Policy</h2>

  <p>If we decide to change our privacy policy, we will post those changes on this page, and/or update the Privacy Policy modification date below.</p>

  <p>This policy was last modified on 08/01/20</p>

  <h2>Contacting Us</h2>

  <p>If there are any questions regarding this privacy policy you may contact us using the information below.</p>

  <p>Clark Tree Services</p>

  <p>240-560-TREE (8733)</p>
</div>
)