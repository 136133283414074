import superAgent from 'superagent';

const handleError = error => {
  if (error.status === 500) {  // 401, 403
    error = { ...error, body: { ok: false, message: 'Unable to process your request. Please try again later.' } }
  } 
  return error;
} // end handleError

export const post = (url, { data } = {}) => {
  return superAgent
    .post(url)
    .send(data)
    .catch(handleError)
    .then(res => {
      if (!res.body) res.body = {};
      res = res.body;
      return res;
    });
};
