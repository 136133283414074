import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import { Container, Menu, Icon } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';
import './App.css';

import Home from "./pages/home"; 
import About from "./pages/about"; 
import Services from "./pages/services";
import Reviews from "./pages/reviews";
import Contact from "./pages/contact";
import PrivacyPolicy from "./pages/privacy-policy";

import { parse } from 'url';
import { homepage } from '../package.json';
const basename = parse(homepage).pathname;

function App() {

  const handleMenu = (remove_show) => {
    let item = document.getElementById("top-nav-menu");
    if ( item.classList.contains('show-menu') || remove_show ) { item.classList.remove('show-menu' ); }
    else if ( !remove_show ) { item.classList.add('show-menu' ); }
  } // end handleMenu

  return (
    <BrowserRouter basename={basename}>
      <div className="App">
        <div className="header-wrapper">
          <Container>
            <Menu stackable borderless className="top-nav" id="top-nav-menu">
              <Menu.Item header className="top-logo">
                <Link to="" className="a-logo" title="Clark Tree Services">Clark Tree Services</Link>
                {/* <div className="description">Let us know if you need some trees cut.</div> */}
                <Icon bordered link name='sidebar' className="menu-btn" onClick={()=>handleMenu()} />
              </Menu.Item>
              
              
              <Menu.Menu position='right' className="nav-menu" >
                <Menu.Item as={Link} link to="/services" onClick={()=>handleMenu(true)}>Our Services</Menu.Item>
                <Menu.Item as={Link} link to="/reviews" onClick={()=>handleMenu(true)}>Reviews</Menu.Item>
                <Menu.Item as={Link} link to="/about" onClick={()=>handleMenu(true)}>About</Menu.Item>
                <Menu.Item as={Link} link to="/contact" onClick={()=>handleMenu(true)}>Contact</Menu.Item>
              </Menu.Menu>
            </Menu>
          </Container>
        </div>

        <div className="content-wrapper mb-3">
          <Container>
            <Switch>
              <Route exact path={'/'} component={Home} />
              <Route exact path={'/about'} component={About} />
              <Route exact path={'/reviews'} component={Reviews} />
              <Route exact path={'/services'} component={Services} />
              <Route exact path={'/contact'} component={Contact} />
              <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
            </Switch>
          </Container>
        </div>

        <div id="footer-area">
          <div>
            <div>Clark Tree Services © 2020</div>
            <div>Call us at <a href="tel:240-560-8733">240-560-TREE (8733)</a></div>
            <div><Link to="/privacy-policy" >Privacy Policy</Link></div>
          </div>
        </div>

      </div>
    </BrowserRouter>
  );
}

export default App;